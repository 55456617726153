<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Rank the solvents according to their polarities, from most polar to least polar.
      </p>

      <drag-to-rank-input
        v-model="inputs.studentAnswers"
        :items="itemsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Most polar"
        append-text="Least polar"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA7_Q17',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      items: ['water', 'ethanol', 'acetone', 'ethyl acetate', 'hexane'],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    itemsShuffled() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
